import PropTypes from 'prop-types';
import { Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useSnackbar } from 'src/components/snackbar';
import { copyToClipboard } from 'src/utils/general';
import { Scrollbar } from '../../landing';
import Link from '../../link/link';
// @mui
//

// ----------------------------------------------------------------------

const LEARN_MORE_URL = null;

export default function OnboardingStepSubdomainSelf({ trackingTag }) {
  const { enqueueSnackbar } = useSnackbar();

  const cellStyle = { borderBottom: (theme) => `dashed 1px ${theme.palette.divider}!important` };

  const copyText = (string) => {
    copyToClipboard(string);
    enqueueSnackbar('Copied to Clipboard.', { autoHideDuration: 1500 });
  };

  return <Stack spacing={2}>
    <Box sx={{ mb: 1 }}>
      Implement this CNAME in your website DNS settings. This will enable scripts to be loaded from your domain and the use of First Party Cookies. This is not a required step, but definitely recommended to make the most out of your tracking setup. {LEARN_MORE_URL &&
    <Link href={LEARN_MORE_URL} target='_blank'>Learn more</Link>}
    </Box>
    <Stack>
      <TableContainer sx={{ overflow: 'unset', my: 1 }}>
        <Scrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '20%', ...cellStyle }}>Type</TableCell>

                <TableCell sx={{ width: '40%', ...cellStyle }}>Host</TableCell>

                <TableCell sx={{ width: '40%', ...cellStyle }}>Points To</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell sx={cellStyle}>
                  <Typography>CNAME</Typography>
                </TableCell>

                <TableCell sx={cellStyle}>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Typography>{trackingTag.domain}</Typography>
                    <Button color='accent' size='small' onClick={() => copyText(trackingTag.domain)}>Copy</Button>
                  </Stack>
                </TableCell>

                <TableCell sx={cellStyle}>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Typography>{trackingTag.fallback_domain_override ?? trackingTag.fallback_domain}</Typography>
                    <Button color='accent' size='small' onClick={() => copyText(trackingTag.fallback_domain_override ?? trackingTag.fallback_domain)}>Copy</Button>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </Stack>
  </Stack>;
}


OnboardingStepSubdomainSelf.propTypes = {
  trackingTag: PropTypes.object,
};


'use client';

import { Box, Container, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Iconify from 'src/components/iconify';
import * as PropTypes from 'prop-types';
import { Label } from 'src/components/landing';
import { alpha } from '@mui/material/styles';
import { formControlLabelClasses } from '@mui/material/FormControlLabel';
import InstallationWooCommerce from 'src/sections/onboarding/installation-methods/installation-woocommerce';
import InstallationShopify from 'src/sections/onboarding/installation-methods/installation-shopify';
import InstallationGTM from 'src/sections/onboarding/installation-methods/installation-gtm';
import PrismicTypography from 'src/components/prismic/prismic-typography';
import InstallationManual from 'src/sections/onboarding/installation-methods/installation-manual';
import { useState } from 'react';
import InstallationStripe from '../../sections/onboarding/installation-methods/installation-stripe';
import PrismicOnboardingNavigation from '../../components/prismic/onboarding/prismic-onboarding-navigation';
import PrismicOnboardingShare from '../../components/prismic/onboarding/prismic-onboarding-share';
import { truncate } from '../../utils/format-string';

export const DEFAULT_INSTALLATION_METHODS = ['gtm', 'manual'];

export const INSTALLATION_METHODS = {
  gtm: {
    value: 'gtm',
    title: 'Google Tag Manager',
    description: '',
    icon: 'logos:google-tag-manager',
    caption: '',
    buttonTitle: 'Select',
    cmsTitle: null,
  },
  manual: {
    value: 'manual',
    title: 'Standard Installation',
    description: '',
    icon: 'mdi:code-tags',
    caption: '',
    buttonTitle: 'Select',
    cmsTitle: null,
  },
  woocommerce: {
    value: 'woocommerce',
    title: 'WooCommerce Plugin',
    description: '',
    icon: 'logos:woocommerce-icon',
    caption: 'Recommended ~5min',
    buttonTitle: 'Select',
    cmsTitle: 'WooCommerce',
  },
  shopify: {
    value: 'shopify',
    title: 'Shopify App',
    description: '',
    icon: 'logos:shopify',
    caption: 'Recommended ~5min',
    buttonTitle: 'Select',
    cmsTitle: 'Shopify',
  },
  stripe: {
    value: 'stripe',
    title: 'Stripe App',
    description: '',
    icon: 'logos:stripe',
    caption: 'Recommended ~5min',
    buttonTitle: 'Select',
    cmsTitle: 'Stripe',
  },
};

function getInstallationMethods(installationMethods) {
  const defaultInstallationMethod = INSTALLATION_METHODS;

  installationMethods.forEach((installationMethod) => {
    defaultInstallationMethod[installationMethod.alias] = {
      ...defaultInstallationMethod[installationMethod.alias],
      ...installationMethod,
    };
  });

  return defaultInstallationMethod;
}

function getTitle(websiteType, installationMethods, trackingTag) {
  const installationMethod = installationMethods.find((method) => method.alias === websiteType);

  if (installationMethod?.website_detected_title) {
    const title = installationMethod.website_detected_title;
    const websiteTitle = truncate(trackingTag?.title || 'Your website', 30);

    return title.replace('{websiteTitle}', websiteTitle);
  }

  const cmsGuess =
    websiteType && INSTALLATION_METHODS[websiteType] ? INSTALLATION_METHODS[websiteType] : null;

  if (cmsGuess?.cmsTitle) {
    return `${trackingTag?.title || 'Your website'} appears to be running on ${cmsGuess.cmsTitle}!`;
  }
  return `Tracklution can be installed on ${trackingTag?.title || 'Your website'}!`;
}

function getDescription(websiteType, installationMethods) {
  const installationMethod = installationMethods.find((method) => method.alias === websiteType);

  if (installationMethod?.website_detected_description?.[0]?.text) {
    return (
      <PrismicTypography sx={{ '& strong': { color: 'accent.main' } }}>
        {installationMethod.website_detected_description}
      </PrismicTypography>
    );
  }
  const cmsGuess =
    websiteType && INSTALLATION_METHODS[websiteType] ? INSTALLATION_METHODS[websiteType] : null;

  if (cmsGuess?.cmsTitle) {
    return (
      <>
        On average, our {cmsGuess.cmsTitle} users experience{' '}
        <Box component="span" sx={{ color: 'accent.main', fontWeight: 600 }}>
          +35.1 % improved conversions
        </Box>
        . With Tracklution you will future-proof you conversion tracking.
      </>
    );
  }
  return (
    <>
      On average, our users experience
      <Box component="span" sx={{ color: 'accent.main', fontWeight: 600 }}>
        {' '}
        +34.2 % improved conversions{' '}
      </Box>
      with the complete installation. With Tracklution you will future-proof you conversion tracking.
    </>
  );
}

/**
 * @typedef {import('@prismicio/client').Content.OnboardingInstallationMethodsSlice} OnboardingInstallationMethodsSlice
 * @typedef {import('@prismicio/react').SliceComponentProps<OnboardingInstallationMethodsSlice>} OnboardingInstallationMethodsProps
 * @param {OnboardingInstallationMethodsProps}
 */
const OnboardingInstallationMethods = ({ slice, context }) => {
  const disableNext = context?.disableNext;

  const [isAllInstallationMethodsVisible, setIsAllInstallationMethodsVisible] = useState(false);
  const [selectedInstallationMethod, setSelectedInstallationMethod] = useState(context?.selectedInstallationMethod || slice.primary.default_installation_method);

  const noPadding = null;

  const trackingTag = context?.trackingTag;
  const cmsGuesses = trackingTag?.event_source_system ? [trackingTag?.event_source_system] : context?.cmsGuesses;
  const onNext = context?.onNext;
  const onPrevious = context?.onPrevious;
  const isCreating = context?.isCreating;
  const onRefresh = context?.onRefresh;
  const eventCount = context?.eventCount;
  const webhookCount = context?.webhookCount;
  const dnsStatus = context?.dnsStatus;

  const handleInstallationMethodSelected = (newSelectedInstallationMethod) => {
    context?.onInstallationMethodSelected(newSelectedInstallationMethod);
    setSelectedInstallationMethod(newSelectedInstallationMethod);
  };

  if (selectedInstallationMethod === 'woocommerce') {
    return (
      <InstallationWooCommerce
        trackingTag={trackingTag}
        onPrevious={() => handleInstallationMethodSelected(null)}
        // onNext={onNext}
        noPadding={noPadding}
        onRefresh={onRefresh}
        eventCount={eventCount}
        webhookCount={webhookCount}
        dnsStatus={dnsStatus}
        isDataRequiredToContinue={slice?.primary?.leave_requirement_event_data}
      />
    );
  }

  if (selectedInstallationMethod === 'shopify') {
    return (
      <InstallationShopify
        trackingTag={trackingTag}
        onPrevious={() => handleInstallationMethodSelected(null)}
        onNext={onNext}
        noPadding={noPadding}
        onRefresh={onRefresh}
        eventCount={eventCount}
        webhookCount={webhookCount}
        dnsStatus={dnsStatus}
        isDataRequiredToContinue={slice?.primary?.leave_requirement_event_data}
      />
    );
  }

  if (selectedInstallationMethod === 'gtm') {
    return (
      <InstallationGTM
        trackingTag={trackingTag}
        onPrevious={() => handleInstallationMethodSelected(null)}
        onNext={onNext}
        noPadding={noPadding}
        onRefresh={onRefresh}
        eventCount={eventCount}
        webhookCount={webhookCount}
        dnsStatus={dnsStatus}
        isDataRequiredToContinue={slice?.primary?.leave_requirement_event_data}
      />
    );
  }

  if (selectedInstallationMethod === 'stripe') {
    return (
      <InstallationStripe
        trackingTag={trackingTag}
        onPrevious={() => handleInstallationMethodSelected(null)}
        onNext={onNext}
        noPadding={noPadding}
        onRefresh={onRefresh}
        eventCount={eventCount}
        webhookCount={webhookCount}
        dnsStatus={dnsStatus}
        isDataRequiredToContinue={slice?.primary?.leave_requirement_event_data}
      />
    );
  }

  if (selectedInstallationMethod === 'manual') {
    return (
      <InstallationManual
        trackingTag={trackingTag}
        onPrevious={() => handleInstallationMethodSelected(null)}
        onNext={onNext}
        noPadding={noPadding}
        onRefresh={onRefresh}
        eventCount={eventCount}
        webhookCount={webhookCount}
        dnsStatus={dnsStatus}
        isDataRequiredToContinue={slice?.primary?.leave_requirement_event_data}
      />
    );
  }

  const primaryCmsGuessAlias =
    cmsGuesses?.length > 0 ? INSTALLATION_METHODS[cmsGuesses[0]]?.alias : null;

  const installationTitle = getTitle(
    primaryCmsGuessAlias || 'manual',
    slice.primary.installation_method_cards,
    trackingTag,
  );
  const installationDescription = getDescription(
    primaryCmsGuessAlias || 'manual',
    slice.primary.installation_method_cards,
    trackingTag,
  );

  const installationMethods = getInstallationMethods(slice.primary.installation_method_cards);

  const methodCount = isAllInstallationMethodsVisible ? Object.keys(INSTALLATION_METHODS).length : (cmsGuesses?.length || 0) + (DEFAULT_INSTALLATION_METHODS?.length || 0);

  const renderInstallationMethodSelect = (
    <Container
      maxWidth={noPadding ? 'lg' : 'md'}
      sx={{ ...(noPadding && { px: { xs: 0, md: 0 } }) }}
    >
      <Stack
        spacing={5}
        sx={{
          justifyContent: 'center',
          minHeight: { xs: noPadding ? 0 : '55vh', md: noPadding ? 0 : '80vh' },
          pt: { xs: 4, md: 3.5 },
          pb: 5,
        }}
      >
        <Stack spacing={1.5}>
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: '2.1rem!important', md: '2.5rem!important' },
              lineHeight: 1.2,
              position: 'relative',
            }}
          >
            {false && onPrevious && (
              <Button
                size="small"
                onClick={onPrevious}
                sx={{ position: 'absolute', left: 0, top: -32, color: 'text.secondary' }}
                startIcon={<Iconify icon="solar:alt-arrow-left-line-duotone" />}
              >
                Back
              </Button>
            )}
            {installationTitle}
          </Typography>
          <Typography variant="body1" gutterBottom>{installationDescription}</Typography>
          <PrismicOnboardingShare trackingTag={trackingTag}
                                  installationMethod={selectedInstallationMethod}
                                  buttonVariant="outlined"
                                  buttonSx={{ bgcolor: 'background.paper' }} />
        </Stack>

        <Stack spacing={2}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            sx={{ alignItems: 'baseline', justifyContent: 'space-between' }}
          >
            <Typography variant="subtitle1">
              {slice.primary.installation_methods_title || 'Choose installation method for'}{' '}
              {trackingTag?.title}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              onClick={() => {
                setIsAllInstallationMethodsVisible((prev) => !prev);
              }}
              sx={{ cursor: 'pointer', fontWeight: 400 }}
            >
              {isAllInstallationMethodsVisible ? 'Show only recommended' : 'Show all methods'}
            </Typography>
          </Stack>

          <RadioGroup onChange={(event) => handleInstallationMethodSelected(event.target.value)}>
            <Box
              rowGap={2.5}
              columnGap={2}
              display="flex"
              flexWrap={methodCount < 5 ? 'nowrap' : 'wrap'}
              flexDirection={{ xs: 'column', md: 'row' }}
              sx={{ pointerEvents: isCreating ? 'none' : 'auto' }}
            >
              {isAllInstallationMethodsVisible &&
                Object.keys(INSTALLATION_METHODS).map((alias) => (
                  <OptionItem
                    key={alias}
                    option={INSTALLATION_METHODS[alias]}
                    selected={selectedInstallationMethod === alias}
                    isCreating={isCreating}
                  />
                ))}
              {!isAllInstallationMethodsVisible && (
                <>
                  {cmsGuesses?.map(
                    (alias) =>
                      installationMethods[alias] && (
                        <OptionItem
                          key={alias}
                          option={installationMethods[alias]}
                          selected={selectedInstallationMethod === alias}
                          isCreating={isCreating}
                          optionCount={methodCount}
                        />
                      ),
                  )}
                  {DEFAULT_INSTALLATION_METHODS.map((alias) => (
                    <OptionItem
                      key={alias}
                      option={installationMethods[alias]}
                      selected={selectedInstallationMethod === alias}
                      isCreating={isCreating}
                      optionCount={methodCount}
                    />
                  ))}
                </>
              )}
            </Box>
          </RadioGroup>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <PrismicOnboardingNavigation onPrevious={onPrevious}
                                       buttonSize="xlarge"
                                       isSkippable={false} />
        </Stack>
      </Stack>
    </Container>
  );

  return (
    <section data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
      {renderInstallationMethodSelect}
    </section>
  );
};

export default OnboardingInstallationMethods;
OnboardingInstallationMethods.propTypes = {
  slice: PropTypes.any,
  context: PropTypes.object,
};

// ----------------------------------------------------------------------

function OptionItem({ option, selected, isCreating, optionCount }) {
  const { value, title, description, icon, caption, buttonTitle } = option;

  const renderLabel = (
    <Stack flexGrow={1} spacing={0.5} sx={{ width: 1, height: '100%' }}>
      <Stack direction="column" alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
        <Stack
          sx={{
            height: 150,
            alignItems: 'center',
            justifyContent: 'center',
            pt: 1,
            opacity: isCreating ? 0.3 : 1,
          }}
        >
          {icon && <Iconify icon={isCreating ? 'svg-spinners:8-dots-rotate' : icon} width={62} />}
        </Stack>

        <Box
          sx={{
            bgcolor: 'background.paper',
            flexGrow: 1,
            p: 3,
            pt: 3,
            pb: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            borderBottomLeftRadius: (theme) => theme.spacing(2),
            borderBottomRightRadius: (theme) => theme.spacing(2),
          }}
        >
          <Stack sx={{ flexGrow: 1 }} spacing={0.5}>
            <Box
              sx={{
                typography: 'subtitle1',
                lineHeight: 1.3,
                textAlign: 'center',
                maxWidth: 200,
                mx: 'auto',
              }}
            >
              {title}
            </Box>

            {description && (
              <PrismicTypography
                component="span"
                sx={{
                  typography: 'body2',
                  color: 'text.secondary',
                  mt: 1,
                  lineHeight: 1.6,
                  textAlign: 'center',
                  maxWidth: 200,
                  mx: 'auto',
                }}
              >
                {description}
              </PrismicTypography>
            )}
          </Stack>
          <Button
            sx={{ mt: 1.25, ml: 'auto', mr: -1.5, mb: 0.5, pointerEvents: 'none' }}
            size="small"
            color="accent"
            disabled={isCreating}
          >
            {buttonTitle || 'Select'}
          </Button>
        </Box>
      </Stack>

      {caption && (
        <Label
          sx={{
            position: 'absolute',
            top: 8,
            left: 8,
            bgcolor: 'accent.main',
            color: 'white',
            height: 24,
            paddingLeft: 1.2,
            paddingRight: 1.2,
            borderRadius: 1.25,
            maxWidth: 'calc(100% - 16px)',
            overflow: 'hidden',
            justifyContent: 'start',
          }}
        >
          {caption || 'Recommended'}
        </Label>
      )}
    </Stack>
  );

  return (
    <FormControlLabel
      value={value}
      control={<Radio disableRipple sx={{ display: 'none' }} />}
      label={renderLabel}
      disableTypography
      sx={{
        m: 0,
        position: 'relative',
        borderRadius: 2,
        flexGrow: 1,
        maxWidth: 440,
        display: 'flex',
        width: optionCount < 5 ? '100%' : null,
        boxShadow: (theme) => `0 3px 5px 0px ${alpha(theme.palette.grey[500], 0.3)}`,
        border: (theme) => `dashed 1px ${alpha(theme.palette.grey[500], 0.5)}`,
        ...(selected && {
          boxShadow: (theme) =>
            `0 10px 5px 5px ${alpha(theme.palette.grey[500], 0.5)}, 0 0 0 2px ${
              theme.palette.text.primary
            }`,
        }),
        [`& .${formControlLabelClasses.label}`]: {
          width: 1,
        },
        '&:hover': {
          ...(!selected && {
            boxShadow: (theme) => `0 5px 10px 5px ${alpha(theme.palette.grey[500], 0.3)}`,
          }),
        },
      }}
    />
  );
}

OptionItem.propTypes = {
  option: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
    icon: PropTypes.string,
    caption: PropTypes.string,
    buttonTitle: PropTypes.string,
  }),
  selected: PropTypes.bool,
  isCreating: PropTypes.bool,
  optionCount: PropTypes.number,
};

import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
// @mui
//

// ----------------------------------------------------------------------

export default function OnboardingStepSubdomainCoworker({ trackingTag }) {

  return <Stack spacing={2}>
    <Stack>
      Send Subdomain Installation Instructions to Coworker
    </Stack>
  </Stack>;
}


OnboardingStepSubdomainCoworker.propTypes = {
  trackingTag: PropTypes.object,
};


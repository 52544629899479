/* eslint-disable react-hooks/exhaustive-deps */

import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { alpha } from '@mui/material/styles';
import { useCallback, useEffect, useState } from 'react';
import OnboardingStepWebhookSelf from './onboarding-step-webhook-self';
import OnboardingStepNavigation from '../partials/onboarding-step-navigation';
import OnboardingStepWebhookCoworker from './onboarding-step-webhook-coworker';
import OnboardingStepTesting from '../partials/onboarding-step-testing';
import Link from '../../link/link';
// @mui
//

// ----------------------------------------------------------------------

const SENDABLE_TO_COWORKER = false;
const INSTALL_TYPE_TABS = [
    { value: 'install-self', label: 'Install Myself', component: OnboardingStepWebhookSelf },
    ...(SENDABLE_TO_COWORKER ? [{ value: 'install-coworker', label: 'Send to A Coworker', component: OnboardingStepWebhookCoworker }] : []),
  ]
;

export default function OnboardingStepWebhook({ trackingTag, isOnboardingDone, onNext, onRefresh, trackingImportCount }) {
  const [currentTab, setCurrentTab] = useState('install-self');
  const [isStepComplete, setIsStepComplete] = useState(false);
  const [isTesting, setIsTesting] = useState(false);

  const handleTest = () => {
    setIsTesting(true);
  };

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const handleNext = () => {
    setIsTesting(false);
    if (onNext) {
      onNext();
    }
  };

  useEffect(() => {
    let checkCount = 0;
    const testInterval = isTesting && setInterval(() => {
      onRefresh();

      if (trackingImportCount > 0) {
        setIsStepComplete(true);
        setIsTesting(false);
      }
      checkCount += 1;
      if (checkCount > 100) {
        setIsTesting(false);
      }
    }, 3000);

    return () => {
      if (testInterval) {
        clearInterval(testInterval);
      }
    };
  }, [isTesting, trackingImportCount]);

  return <Stack spacing={1}>
    <Stack>
      {INSTALL_TYPE_TABS.length > 1 && <Tabs value={currentTab} onChange={handleChangeTab} sx={{
        mb: 5, boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
      }}>
        {INSTALL_TYPE_TABS.map((tab) => (
          <Tab key={tab.value} value={tab.value} label={tab.label}
               sx={{
                 '&:not(:last-of-type)': { mr: 3 },
               }} />
        ))}
      </Tabs>}
      {INSTALL_TYPE_TABS.map(
        (tab) =>
          tab.value === currentTab && (
            <Box key={tab.value}>
              <tab.component trackingTag={trackingTag} isStepComplete={isStepComplete} />
            </Box>
          ),
      )}
      <Box sx={{ mt: 6 }}>
        <OnboardingStepTesting
          startLabel='Start Listening Webhook'
          testingLabel='Listening Webhook...'
          completeLabel='Data Fired to Webhook'
          onTest={handleTest}
          isTesting={isTesting}
          isComplete={isStepComplete}
        />
      </Box>
      <Link href='https://docs.tracklution.com/installation/setup-tracking-container#step-3-webhook-optional' target='_blank' sx={{ my: 3 }}>
        Read Documentation: Webhook
      </Link>
    </Stack>
    {onNext && <OnboardingStepNavigation onNext={handleNext} isCurrentComplete={isStepComplete} nextLabel='Complete' skipLabel='Skip this step'
                                         isOnboardingDone={isOnboardingDone} />}
  </Stack>;
}


OnboardingStepWebhook.propTypes = {
  trackingTag: PropTypes.object,
  isOnboardingDone: PropTypes.bool,
  onNext: PropTypes.func,
  onRefresh: PropTypes.func,
  trackingImportCount: PropTypes.number,
};


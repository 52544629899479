import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
// @mui
//

// ----------------------------------------------------------------------

export default function OnboardingStepEventCoworker({ trackingTag }) {

  return <Stack spacing={2}>
    <Stack>
      Send Event Installation Instructions to Coworker
    </Stack>
  </Stack>;
}


OnboardingStepEventCoworker.propTypes = {
  trackingTag: PropTypes.object,
};


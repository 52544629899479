import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Iconify from '../../iconify';
import { TextIconLabel } from '../../landing';
// @mui
//

// ----------------------------------------------------------------------

export default function OnboardingStepNavigation({
                                                   onNext,
                                                   isOnboardingDone = false,
                                                   isCurrentComplete = true,
                                                   isSkippable = true,
                                                   nextLabel = 'Next',
                                                   skipLabel = 'Complete later',
                                                 }) {

  if (isOnboardingDone) {
    return <Stack direction='row' spacing={1}>
      <Box flexGrow={1} />
      <Button
        color='accent'
        variant='contained'
        endIcon={<Iconify icon='carbon:chevron-right' />}
        onClick={onNext}
        sx={{ px: 3, py: 1 }}
      >
        {nextLabel}
      </Button>
    </Stack>;
  }

  return <Stack direction='row' spacing={1}>
    <Box flexGrow={1} />
    {!isCurrentComplete && isSkippable && <Button
      onClick={onNext}
      sx={{ px: 3, py: 1, opacity: 0.8 }}
    >
      {skipLabel}
    </Button>}
    {isCurrentComplete && <TextIconLabel
      icon={
        <Iconify
          icon='mingcute:check-fill'
          sx={{ width: 20, height: 20, mr: 1, color: 'success.main' }}
        />
      }
      sx={{ mr: 2, typography: 'subtitle2' }}
      value='Completed!'
    />}
    <Button
      color='accent'
      variant='contained'
      endIcon={<Iconify icon='carbon:chevron-right' />}
      onClick={onNext}
      sx={{ px: 3, py: 1 }}
      disabled={!isCurrentComplete}
    >
      {nextLabel}
    </Button>
  </Stack>;
}


OnboardingStepNavigation.propTypes = {
  onNext: PropTypes.func,
  isOnboardingDone: PropTypes.bool,
  isCurrentComplete: PropTypes.bool,
  isSkippable: PropTypes.bool,
  nextLabel: PropTypes.string,
  skipLabel: PropTypes.string,
};


import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
// @mui
//

// ----------------------------------------------------------------------

export default function OnboardingStepWebhookCoworker({ trackingTag }) {

  return <Stack spacing={2}>
    <Stack>
      Send Webhook Installation Instructions to Coworker
    </Stack>
  </Stack>;
}


OnboardingStepWebhookCoworker.propTypes = {
  trackingTag: PropTypes.object,
};


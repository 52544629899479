import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import Link from '../../link/link';
import OnboardingReadMore from '../partials/onboarding-read-more';
// @mui
//

// ----------------------------------------------------------------------

const LEARN_MORE_URL = null;

export default function OnboardingStepEventSelf({ trackingTag, isDescriptionsVisible }) {

  return <Stack spacing={2}>
    <Box>
      You can either implement the scripts directly to the code of your website or utilise a tag management system such as GTM. {LEARN_MORE_URL &&
    <Link href={LEARN_MORE_URL} target='_blank'>Learn more</Link>}
    </Box>
    <Stack direction='column'>
      <Typography variant='h6' gutterBottom sx={{ mt: 1 }}>1. Implement the main script</Typography>
      {isDescriptionsVisible && <><Typography variant='body2' color='text.secondary' gutterBottom>To ensure optimal functionality, the main script
        must load on every page of your
        website prior to any event-specific scripts. For best practices, we advise embedding it within the <code>{'<head>'}</code> section across all
        pages.
      </Typography>
        <Typography variant='body2' color='text.secondary' sx={{ mb: 2 }}><b>Installing through GTM?</b> If you implement this script via a tag
          management system such as GTM, you can add it as a Custom HTML tag, just bear in mind that this script should be loaded in every page before
          any other Tracklution scripts.
        </Typography></>}

      <Stack component={Paper} sx={{ overflow: 'hidden', mb: 1 }}>
        {trackingTag?.scripts?.script && <SyntaxHighlighter
          language='javascript'
          style={tomorrow}
          wrapLongLines
          showLineNumbers={false}
          customStyle={{ padding: 16, margin: 0, overflow: 'hidden', fontSize: '0.9rem' }}
          children={trackingTag?.scripts?.script}
        />}
      </Stack>
    </Stack>
    <Stack direction='column'>
      <Typography variant='h6' gutterBottom sx={{ mt: 2 }}>2. Implement the event scripts</Typography>
      {isDescriptionsVisible && <><Typography variant='body2' color='text.secondary' gutterBottom>Now we’re getting into action! These are the actual
        events you want to track on
        your website.
      </Typography>
        <Typography variant='body2' color='text.secondary'><b>Installing through GTM?</b> Simply add the scripts as Custom HTML tags. Remember to
          adjust
          the tag settings to guarantee the main script loads before any event scripts.
        </Typography></>}
    </Stack>
    <Stack direction='column'>
      <Typography variant='subtitle1' gutterBottom>Implement PageView</Typography>
      {isDescriptionsVisible && <><Typography variant='body2' color='text.secondary' gutterBottom>With PageView implemented, Tracklution is able to
        capture incoming traffic with
        their URL tracking parameters, so that conversions can be reported back to ad platforms with their unique tracking ID such as click ID.
      </Typography>
        <Typography variant='body2' color='text.secondary' sx={{ mb: 2 }}>Add this script to be loaded on your site with every page view (e.g. inside
          the <code>{'<body>'}</code> section of the site code for every page view).
        </Typography></>}
      <Stack component={Paper} sx={{ overflow: 'hidden', mb: 1 }}>
        {trackingTag?.scripts?.pageView && <SyntaxHighlighter
          language='javascript'
          style={tomorrow}
          wrapLongLines
          showLineNumbers={false}
          customStyle={{ padding: 16, margin: 0, overflow: 'hidden', fontSize: '0.9rem' }}
          children={trackingTag?.scripts?.pageView}
        />}
      </Stack>
    </Stack>
    <Stack direction='column'>
      <Typography variant='subtitle1' gutterBottom>Implement Purchase event</Typography>
      {isDescriptionsVisible && <Typography variant='body2' color='text.secondary' sx={{ mb: 2 }}>This enables you to track Purchases in your site.
        Make sure to replace the
        example content in the script (<code>value</code> and <code>currency</code> parameters) with the actual variables to catch the purchase
        information correctly, e.g. by utilising variables in GTM data layer.
      </Typography>}
      <Stack component={Paper} sx={{ overflow: 'hidden', mb: 1 }}>
        {trackingTag?.scripts?.purchase && <SyntaxHighlighter
          language='javascript'
          style={tomorrow}
          wrapLongLines
          showLineNumbers={false}
          customStyle={{ padding: 16, margin: 0, overflow: 'hidden', fontSize: '0.9rem' }}
          children={trackingTag?.scripts?.purchase}
        />}
      </Stack>
    </Stack>
    <Stack direction='column'>
      <Typography variant='subtitle1' gutterBottom>Implementing any other standard or custom events</Typography>
      {isDescriptionsVisible && <Typography variant='body2' color='text.secondary' sx={{ mb: 2 }}>You can use any events you wish, whether they are
        standard or customer events.
        Just change the event name in the script to match the event name you prefer using and install the script in the desired location or action on
        the site.
      </Typography>}
      <Stack component={Paper} sx={{ overflow: 'hidden' }}>
        {trackingTag?.scripts?.customEvent && <SyntaxHighlighter
          language='javascript'
          style={tomorrow}
          wrapLongLines
          showLineNumbers={false}
          customStyle={{ padding: 16, margin: 0, overflow: 'hidden', fontSize: '0.9rem' }}
          children={trackingTag?.scripts?.customEvent}
        />}
      </Stack>
    </Stack>
    <Stack direction='column'>
      <Typography variant='h6' gutterBottom sx={{ mt: 2 }}>3. Implement ContactInfo for catching contact information</Typography>
      {isDescriptionsVisible && <><Typography variant='body2' color='text.secondary' sx={{ mb: 2 }}>Embed this script within
        the <code>{'<body>'}</code> tag on pages where
        customer information is accessible. {'It\'s'} essential to substitute placeholder values with actual data, like email addresses or phone
        numbers,
        to accurately fill the ContactInfo tag with genuine contact details.
      </Typography>

        <OnboardingReadMore title='Why this is important?' sx={{ mb: 1 }}>
          <Typography variant='body2' color='text.secondary' sx={{ mt: 0.5, mb: 1 }}>Implementing these enhancements {'isn\'t'} strictly necessary but
            highly
            recommended to maximize your server-side tracking benefits. {'Here\'s'} why:
          </Typography>
          <Typography variant='subtitle1'>Enable Advanced Features</Typography>
          <Typography variant='body2' color='text.secondary' sx={{ mb: 1 }}>
            Unlock capabilities like Enhanced Conversions (Google Ads) and Advanced Matching (Meta). This includes
            cross-channel remarketing without relying on third-party cookies, capturing otherwise lost conversions (e.g., from missing click IDs),
            identifying returning users, and enhancing marketing campaign data for better performance.
          </Typography>
          <Typography variant='subtitle1'>Integrate External Data</Typography>
          <Typography variant='body2' color='text.secondary' sx={{ mb: 1 }}>
            Easily incorporate data from other systems, such as tracking offline conversions, through efficient matching via Webhook delivery to
            Tracklution.
          </Typography>
          <Typography variant='subtitle1'>Your Data Remains Yours</Typography>
          <Typography variant='body2' color='text.secondary' sx={{ mb: 3 }}>
            As a technology provider, we emphasize that your data is exclusively yours. Our access is strictly confined to {'what\'s'} necessary for
            delivering server-side tracking, without any claim to your data.
          </Typography>
        </OnboardingReadMore>

        <OnboardingReadMore title='How to implement this?' sx={{ mb: 3 }}>
          <Typography variant='subtitle1' sx={{ mt: 1 }}>When to Trigger</Typography>
          <Typography variant='body2' color='text.secondary' sx={{ mb: 2 }}>The script can be executed at any session point where relevant information
            is available, such as on an order or thank you page.
          </Typography>
          <Typography variant='subtitle1'>Data Preparation</Typography>
          <Typography variant='body2' color='text.secondary' sx={{ mb: 2 }}>{'It\'s'} vital to replace placeholder values with actual data variables
            (e.g.,
            email or phone number variables from the GTM data layer) to populate the ContactInfo tag accurately.
          </Typography>
          <Typography variant='subtitle1'>Data Privacy</Typography>
          <Typography variant='body2' color='text.secondary' sx={{ mb: 2 }}>Information can be submitted in plain text; Tracklution will hash it
            before
            forwarding to ad platforms. You may also hash data (sha256) on your end before submission.
          </Typography>
          <Typography variant='subtitle1'>Data Selection</Typography>
          <Typography variant='body2' color='text.secondary' sx={{ mb: 2 }}>Depending on data availability, you may choose to send all or only a
            subset
            of the fields provided in the example, with email and phone number being the minimum for optimal matching.
          </Typography>
          <Typography variant='subtitle1'>Alternative Delivery Methods</Typography>
          <Typography variant='body2' color='text.secondary' sx={{ mb: 2 }}>If contact information {'isn\'t'} available during the session or you
            prefer
            a
            server-side approach, implement the ContactInfo tag with a unique external ID <code>externalId</code> (e.g., Order ID). Then, send
            additional contact info via webhook using the same <code>externalId</code> for seamless session data matching.
          </Typography>
        </OnboardingReadMore></>}
      <Stack component={Paper} sx={{ overflow: 'hidden' }}>
        {trackingTag?.scripts?.contactInfo && <SyntaxHighlighter
          language='javascript'
          style={tomorrow}
          wrapLongLines
          showLineNumbers={false}
          customStyle={{ padding: 16, margin: 0, overflow: 'hidden', fontSize: '0.9rem' }}
          children={trackingTag?.scripts?.contactInfo}
        />}
      </Stack>
    </Stack>
  </Stack>;
}


OnboardingStepEventSelf.propTypes = {
  trackingTag: PropTypes.object,
  isDescriptionsVisible: PropTypes.bool,
};


import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import Link from '../../link/link';
import OnboardingReadMore from '../partials/onboarding-read-more';
// @mui
//

// ----------------------------------------------------------------------

const LEARN_MORE_URL = null

export default function OnboardingStepWebhookSelf({ trackingTag }) {

  return <Stack spacing={2}>
    <Box sx={{mb: 1}}>
      Webhook provides a seamless and efficient way to bring additional tracking data to Tracklution, such as offline conversions and enhancing conversion data. Whether {'it\'s'} obtaining insights from sources that may not be directly linked to the website or safeguarding sensitive information, you can import it from various external systems, including CRM, booking systems, billing systems, ERP, and calling systems. {LEARN_MORE_URL && <Link href={LEARN_MORE_URL} target='_blank'>Learn more</Link>}
    </Box>

    <OnboardingReadMore title='Registering events with Webhook' sx={{ mb: 0 }}>
      <Typography variant='body2' color='text.secondary' sx={{ mt: 1, mb: 2 }}>Record events that happen outside of your website (“offline conversions”). These conversion events could be for example phone calls, purchases made in brick-and-mortar shops, bookings made in a booking system, validated leads in a CRM system, and so forth. This is also the way to go if you wish to send in Purchase events with profit margin data instead of pure revenue data.
      </Typography>
      <Typography variant='body2' color='text.secondary' sx={{ mb: 2 }}>Events sent via webhook can be matched to web sessions if matching data is available.
      </Typography>

      <Typography variant='subtitle1'>GET example</Typography>
      <Stack component={Paper} sx={{ overflow: 'hidden', mb: 2.5 }}>
        {trackingTag?.scripts?.webhookTrackGetExample && <SyntaxHighlighter
          style={tomorrow}
          wrapLongLines
          showLineNumbers={false}
          customStyle={{ padding: 16, margin: 0, overflow: 'hidden', fontSize: '0.9rem' }}
          children={trackingTag?.scripts?.webhookTrackGetExample}
        />}
      </Stack>

      <Typography variant='subtitle1'>POST example</Typography>
      <Stack component={Paper} sx={{ overflow: 'hidden', mb: 1 }}>
        {trackingTag?.scripts?.webhookTrackPostExample && <SyntaxHighlighter
          style={tomorrow}
          wrapLongLines
          showLineNumbers={false}
          customStyle={{ padding: 16, margin: 0, overflow: 'hidden', fontSize: '0.9rem' }}
          children={trackingTag?.scripts?.webhookTrackPostExample}
        />}
      </Stack>
    </OnboardingReadMore>

    <OnboardingReadMore title='Enrich existing data with Webhook' sx={{ mb: 0 }}>
      <Typography variant='body2' color='text.secondary' sx={{ mt: 1, mb: 2 }}>Enhance existing web conversion data from sources that may not be directly linked to the website or safeguard sensitive information that you {'don\'t'} want to be available to anyone via the browser.
      </Typography>
      <Typography variant='body2' color='text.secondary' sx={{ mb: 2 }}>Enriching could mean additional contact information or for example setting value for Purchase event in case the value is not available on the web (e.g. recording profit data instead of revenue data).
      </Typography>
      <Typography variant='subtitle1' sx={{ mb: 1 }}>Example of ContactInfo pixel fired when purchase happens
      </Typography>
      <Stack component={Paper} sx={{ overflow: 'hidden', mb: 2.5 }}>
        {trackingTag?.scripts?.webhookEnrichContactInfo && <SyntaxHighlighter
          language='javascript'
          style={tomorrow}
          wrapLongLines
          showLineNumbers={false}
          customStyle={{ padding: 16, margin: 0, overflow: 'hidden', fontSize: '0.9rem' }}
          children={trackingTag?.scripts?.webhookEnrichContactInfo}
        />}
      </Stack>
      <Typography variant='subtitle1' sx={{ mb: 1 }}>Example for webhook POST request when purchase happens
      </Typography>
      <Stack component={Paper} sx={{ overflow: 'hidden', mb: 2 }}>
        {trackingTag?.scripts?.webhookEnrichPostExample && <SyntaxHighlighter
          style={tomorrow}
          wrapLongLines
          showLineNumbers={false}
          customStyle={{ padding: 16, margin: 0, overflow: 'hidden', fontSize: '0.9rem' }}
          children={trackingTag?.scripts?.webhookEnrichPostExample}
        />}
      </Stack>
      <Typography variant='body2' color='text.secondary'>
        To enrich existing data successfully, Tracklution has to be able to match webhook data with existing session data.
      </Typography>
    </OnboardingReadMore>

  </Stack>;
}


OnboardingStepWebhookSelf.propTypes = {
  trackingTag: PropTypes.object,
};


'use client';

import Stack from '@mui/material/Stack';
import { Accordion, AccordionDetails, AccordionSummary, CardHeader, Container, Divider, Paper, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState } from 'react';
import OnboardingStepEvent from 'src/components/onboarding/onboarding-steps/onboarding-step-event';
import OnboardingStepSubdomain from 'src/components/onboarding/onboarding-steps/onboarding-step-subdomain';
import OnboardingStepWebhook from 'src/components/onboarding/onboarding-steps/onboarding-step-webhook';
import PrismicOnboardingShare from 'src/components/prismic/onboarding/prismic-onboarding-share';
import { truncate } from 'src/utils/format-string';

// ----------------------------------------------------------------------
const ONBOARDING_STEPS = [
  {
    value: 1,
    heading: 'Step 1',
    subHeading: 'Install Tracking Pixels',
    stepType: 'required',
    component: OnboardingStepEvent,
  },
  {
    value: 2,
    heading: 'Step 2',
    subHeading: 'DNS settings for first-party data',
    stepType: 'recommended',
    component: OnboardingStepSubdomain,
  },
  {
    value: 3,
    heading: 'Step 3',
    subHeading: 'Webhook',
    stepType: 'optional',
    component: OnboardingStepWebhook,
  },
];

const getStepTypeColor = (stepType) => {
  if (stepType === 'required') {
    return 'accent.main';
  }
  if (stepType === 'recommended') {
    return 'accent.main';
  }
  if (stepType === 'optional') {
    return 'text.secondary';
  }
  return 'text.secondary';
};

export default function InstallationManual({
                                             trackingTag,
                                             onNext,
                                             onPrevious,
                                             onRefresh,
                                             noPadding,
                                             eventCount,
                                             webhookCount,
                                             dnsStatus,
                                           }) {

  const [controlledValue, setControlledValue] = useState(1);
  const [showCompletedMessage, setShowCompletedMessage] = useState(true);
  const [isDescriptionsVisible, setIsDescriptionsVisible] = useState(true);
  const [isOnboardingDone] = useState(eventCount > 0 || webhookCount > 0);

  const handleChangeControlled = (panel) => (event, isExpanded) => {
    setControlledValue(isExpanded ? panel : false);
  };

  // const renderInstallation = (
  //   <Container
  //     maxWidth="xl"
  //     sx={{ ...(noPadding && { px: { xs: 0, md: 0 } }), display: 'flex', justifyContent: 'center' }}
  //   >
  //     <Stack sx={{ mx: 'auto' }} direction="row" spacing={1.5}>
  //       <Iconify icon="svg-spinners:8-dots-rotate" width={24} color="accent.main" />
  //
  //       <Stack spacing={0.5}>
  //         <Typography variant="subtitle1" sx={{ lineHeight: 1, mt: 0.5 }}>Loading Dashboard...</Typography>
  //         <Typography variant="caption" sx={{ lineHeight: 1, cursor: 'pointer', textDecoration: 'underline' }} onClick={onPrevious}>Back</Typography>
  //       </Stack>
  //     </Stack>
  //   </Container>
  // );

  return <Container
    maxWidth="xl"
    sx={{ mt: { xs: 4, md: noPadding ? 4 : 12 }, ...(noPadding && { px: { xs: 0, md: 0 } }), display: 'flex', justifyContent: 'center' }}
  >
    <Stack sx={{ mx: 'auto', width: 1150, maxWidth: '100%' }} direction="row" spacing={1.5}>
      <Paper
        variant="outlined"
        sx={{
          borderRadius: 1.5,
          borderStyle: 'dashed',
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
          position: 'relative',
          mb: 6,
          overflow: 'hidden',
        }}
      >
        <CardHeader
          sx={{
            bgcolor: 'background.paper',
            borderBottom: '1px dashed',
            borderColor: 'divider',
            pb: { xs: 3, md: 2.5 },
          }}
          title={
            <Stack
              direction={{ xs: 'column-reverse', md: 'row' }}
              alignItems="start"
              spacing={1}
              sx={{ mt: { xs: 0, md: 2 }, pl: { xs: 0, md: 1 } }}
            >
              <Stack sx={{ mt: { xs: 2, md: 0 }, width: '100%' }} direction="row">
                <Typography
                  variant="h2"
                  sx={{
                    lineHeight: 1.2,
                    mb: 0.5,
                    fontSize: { xs: '1.8rem!important', md: '2.5rem!important' },
                  }}
                >
                  Install Tracklution to <Box component="span"
                                              sx={{ fontWeight: 600 }}>{truncate(trackingTag?.title || 'Your website', 30)}</Box>
                </Typography>
              </Stack>
              {false && <Stack
                sx={{
                  flexGrow: 1,
                  textAlign: { xs: 'left', md: 'right' },
                  mt: { xs: 0, md: -2 },
                  mr: { xs: 0, md: 1 },
                  whiteSpace: 'nowrap',
                }}
              >
                <Typography variant="h5">{trackingTag?.title}</Typography>
                <Typography variant="subtitle1" color="text.secondary" sx={{ mt: -0.5 }}>
                  {trackingTag?.clean_website_domain}
                </Typography>
              </Stack>}
            </Stack>
          }
          subheader={
            <Stack
              spacing={2}
              sx={{ mt: { xs: 2, md: 3 }, mb: { xs: 0, md: 2 }, pl: { xs: 0, md: 1 } }}
              alignItems="start"
            >
              <Stack sx={{ mb: 0.5 }} direction="row" alignItems="center" spacing={1} flexWrap="wrap">
                <PrismicOnboardingShare installationMethod="manual" trackingTag={trackingTag} buttonVariant="outlined" buttonSx={{ bgcolor: 'background.paper' }} />
                <Button
                  variant="outlined"
                  onClick={onPrevious}
                  sx={{ textTransform: 'none', bgcolor: 'background.paper' }}
                >
                  Change installation method
                </Button>
              </Stack>
              {isDescriptionsVisible && (
                <>
                  <Typography variant="body1" color="text.secondary">
                    Now it`s time to install Tracklution tracking pixels to your website. It’s super
                    simple: The installation happens just like installing any other tracking pixels.
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    This is a necessary step for Tracklution to be able to capture events in your
                    website and thus pass them on to your selected data destinations, such as Meta,
                    Google Ads or Google Analytics.
                  </Typography>
                </>
              )}
            </Stack>
          }
          disableTypography
        />

        <Stack
          spacing={0}
          direction="row"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          sx={{
            pt: 3,
            pb: 5,
            pl: { xs: 1, md: 8 },
            pr: { xs: 1, md: 3 },
            // py: 4.5,
            // px: { xs: 0, md: 4 },
            minHeight: 180,
          }}
        >
          {ONBOARDING_STEPS.map((item, index) => {
            const { value, heading, subHeading, stepType } = item;

            const isSelectedStep = controlledValue === value;
            return (
              <Accordion
                key={value}
                expanded={isSelectedStep}
                onChange={handleChangeControlled(value)}
                sx={{ mt: 1, width: '100%' }}
              >
                <AccordionSummary
                  expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
                  sx={{ '& > div': { alignItems: 'center' }, minHeight: 70, px: 3, py: 1, pb: 0 }}
                >
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    sx={{ width: '100%', alignItems: { xs: 'none', md: 'center' } }}
                  >
                    <Typography
                      variant={isSelectedStep ? 'h5' : 'h6'}
                      sx={{ width: { xs: '100%', md: '25%' }, flexShrink: 0 }}
                      color={isSelectedStep ? 'accent.main' : 'text.primary'}
                    >
                      {heading}
                    </Typography>
                    <Stack direction="row" sx={{ alignItems: { xs: 'none', md: 'center' } }}>
                      <Typography
                        variant={isSelectedStep ? 'h5' : 'subtitle1'}
                        sx={{ color: isSelectedStep ? 'text.primary' : 'text.secondary' }}
                      >
                        {subHeading}
                      </Typography>
                      {stepType && (
                        <Typography
                          variant="subtitle2"
                          color={getStepTypeColor(stepType)}
                          sx={{ ml: 1, mt: 0.125 }}
                        >
                          ({stepType})
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 3, pt: 0, paddingLeft: { xs: 3, md: 'calc(25% + 8px)' } }}>
                  <item.component
                    trackingTag={trackingTag}
                    isOnboardingDone={isOnboardingDone}
                    onNext={onNext}
                    onRefresh={onRefresh}
                    trackingEventCount={eventCount}
                    trackingImportCount={webhookCount}
                    isDescriptionsVisible={isDescriptionsVisible}
                  />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Stack>
        {showCompletedMessage && (
          <Box sx={{ mt: -1 }}>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <Stack sx={{ p: { xs: 3, md: 5 }, pt: { xs: 5, md: 6 }, bgcolor: 'background.paper' }}>
              <Typography variant="h4" sx={{ mb: 2 }}>
                Congratulations, you’re all done! 🥳
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                Now you start seeing session data populating in real time in your Tracking Container
                in the “Processed Data” table. This helps you to easily validate that your setup was
                successful and that all desired events are being correctly recorded.
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                If your data looks correct, the only thing left to do is to activate Connectors –
                enabling data destinations where you want your conversion data to be sent!
              </Typography>
              <Button
                onClick={onNext}
                color="accent"
                size="large"
                variant="contained"
                endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={18} sx={{ ml: -0.5 }} />}
                sx={{ ml: 'auto', mt: { xs: 2, md: 4 } }}
              >
                Continue
              </Button>
            </Stack>
          </Box>
        )}
      </Paper>
    </Stack>
  </Container>;
}

InstallationManual.propTypes = {
  trackingTag: PropTypes.object,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onRefresh: PropTypes.func,
  noPadding: PropTypes.bool,
  eventCount: PropTypes.number,
  webhookCount: PropTypes.number,
  dnsStatus: PropTypes.number,
};

/* eslint-disable react-hooks/exhaustive-deps */

import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { alpha } from '@mui/material/styles';
import { useCallback, useState } from 'react';
import { fetcher } from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';
import OnboardingStepSubdomainSelf from './onboarding-step-subdomain-self';
import OnboardingStepNavigation from '../partials/onboarding-step-navigation';
import OnboardingStepSubdomainCoworker from './onboarding-step-subdomain-coworker';
import OnboardingStepTesting from '../partials/onboarding-step-testing';
import Link from '../../link/link';
// @mui
//

// ----------------------------------------------------------------------

const SENDABLE_TO_COWORKER = false;
const INSTALL_TYPE_TABS = [
  { value: 'install-self', label: 'Install Myself', component: OnboardingStepSubdomainSelf },
  ...(SENDABLE_TO_COWORKER ? [{ value: 'install-coworker', label: 'Send to A Coworker', component: OnboardingStepSubdomainCoworker }] : []),
];

const DEFAULT_MESSAGE = '';

export default function OnboardingStepSubdomain({ trackingTag, isOnboardingDone, onNext }) {
  const { enqueueSnackbar } = useSnackbar();

  const [currentTab, setCurrentTab] = useState('install-self');
  const [isStepComplete, setIsStepComplete] = useState(false);
  const [isTesting, setIsTesting] = useState(false);
  const [testMessage, setTestMessage] = useState(DEFAULT_MESSAGE);

  const handleTest = async () => {
    setIsTesting(true);
    setIsStepComplete(false);
    setTestMessage(DEFAULT_MESSAGE);

    const url = `/guest/tracking/${trackingTag.hash}/test/dns`;
    try {
      const { success, testSuccess, message } = await fetcher(url);

      setIsTesting(false);

      if (!success) {
        enqueueSnackbar('DNS testing could not be done.', { variant: 'error' });
        return;
      }

      setTestMessage(message);

      if (!testSuccess) {
        enqueueSnackbar('DNS setting not yet found.', { variant: 'warning' });
        return;
      }
      setIsStepComplete(true);
    } catch (error) {
      console.error(error);

      setIsTesting(false);
      enqueueSnackbar('Error on DNS testing.', { variant: 'error' });
    }
  };

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  return <Stack spacing={1}>
    <Stack>
      {INSTALL_TYPE_TABS.length > 1 && <Tabs value={currentTab} onChange={handleChangeTab} sx={{
        mb: 5, boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
      }}>
        {INSTALL_TYPE_TABS.map((tab) => (
          <Tab key={tab.value} value={tab.value} label={tab.label}
               sx={{
                 '&:not(:last-of-type)': { mr: 3 },
               }} />
        ))}
      </Tabs>}
      {INSTALL_TYPE_TABS.map(
        (tab) =>
          tab.value === currentTab && (
            <Box key={tab.value}>
              <tab.component trackingTag={trackingTag} isStepComplete={isStepComplete} />
            </Box>
          ),
      )}
      <Box sx={{ mt: 3 }}>
        <OnboardingStepTesting
          startLabel='Verify DNS installation'
          testingLabel='Verifying DNS...'
          completeLabel='DNS Verified'
          message={testMessage}
          onTest={handleTest}
          isTesting={isTesting}
          isComplete={isStepComplete}
        />
      </Box>
      <Link href='https://docs.tracklution.com/installation/setup-tracking-container#step-2-set-dns-for-first-party-data-collection-recommended' target='_blank' sx={{ my: 3 }}>
        Read Documentation: DNS setting
      </Link>
    </Stack>
    {onNext && <OnboardingStepNavigation onNext={onNext} isCurrentComplete={isStepComplete} isOnboardingDone={isOnboardingDone} />}
  </Stack>;
}


OnboardingStepSubdomain.propTypes = {
  trackingTag: PropTypes.object,
  isOnboardingDone: PropTypes.bool,
  onNext: PropTypes.func,
};

